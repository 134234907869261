import { useEffect, useRef } from 'react';

const useTimeout = (callback: Function, delay: number | null) => {
  const savedCallback = useRef(callback);

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // @ts-ignore
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      const id = setTimeout(tick, delay);

      return () => clearTimeout(id);
    }

    return false;
  }, [delay]);
};

export default useTimeout;

const customMediaQuery = (minWidth: number) =>
  `@media (min-width: ${minWidth}px)`;

export const QuerySizes = {
  phone: 0,
  tablet: 760
};

const Query = {
  phone: customMediaQuery(QuerySizes.phone),
  tablet: customMediaQuery(QuerySizes.tablet)
};

export default Query;

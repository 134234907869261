// import { wrap } from 'gsap';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Provider } from 'react-redux';
import { animated, useTransition } from 'react-spring/web.cjs';
import { PersistGate } from 'redux-persist/integration/react';
import 'animate.css/animate.min.css';
import useTimeout from 'src/hooks/useTimeout';
import Splash from './components/Splash';
import generateStore from './state/reducers';
import GlobalStyle from './styles/GlobalStyle';
const { store, persistor } = generateStore();

const WrapFC: FC<{ element: ReactNode; loaded: boolean }> = ({
  element,
  loaded
}) => {
  const [animating, setAnimating] = useState(true);
  const [reveal, setReveal] = useState(false);

  useTimeout(() => {
    setAnimating(false);
  }, 3000);

  useEffect(() => {
    if (animating === false && loaded === true) {
      setReveal(true);
    }
  }, [animating, loaded]);

  const transitions = useTransition(reveal, null, {
    from: {
      top: '0',
      bottom: '0',
      left: '0',
      right: '0',
      position: 'fixed',
      opacity: 0,
      visibility: 'hidden',
      overflow: 'hidden',
      zIndex: 1
    },
    enter: {
      height: '100%',
      minHeight: '100%',
      opacity: 1,
      position: 'relative',
      visibility: 'visible',
      overflow: 'visible',
      zIndex: 2
    },
    leave: {
      top: '0',
      bottom: '0',
      opacity: 0,
      position: 'fixed',
      zIndex: 1
    }
  });

  // don't show the fake loader while we're developing things.
  if (process.env.NODE_ENV === 'development') {
    return <>{element}</>; // eslint-disable-line react/jsx-no-useless-fragment
  }

  return (
    <>
      {transitions.map(({ item, key, props }) => {
        return item ? (
          <animated.div style={props} key={key}>
            {element}
          </animated.div>
        ) : (
          <animated.div style={props} key={key}>
            <Splash />
          </animated.div>
        );
      })}
    </>
  );
};

export const wrapRootElement: FC<{ element: ReactNode }> = ({ element }) => (
  <Provider store={store}>
    <GlobalStyle />
    <Helmet>
      <meta charSet="UTF-8" />
      <meta
        name="viewport"
        content="initial-scale=1.0, width=device-width"
        key="viewport"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <title>Road to Zero</title>
    </Helmet>

    <PersistGate persistor={persistor}>
      {loaded => <WrapFC element={element} loaded={loaded} />}
    </PersistGate>
  </Provider>
);

import React from 'react';
import SVG from './SVG';
const Bubbles = ({ width = 841, height = 432, className = '' }) => (
  <SVG
    width={width}
    height={height}
    title="Bubbles"
    className={className}
    viewBox="0 0 841 432"
  >
    <path
      fill="#444"
      d="M618.578 50.573c-39.307 20.931-89.14 54.022-91.517 82.692-2.37 28.66 64.624 34.513 105.044 31.806 40.424-2.707 137.287 6.549 164.2-19.148 26.918-25.697 3.133-67.313 28.646-97.651 25.514-30.338 15.52-48.31 4.733-47.585-10.78.721-137.083 10.459-211.106 49.886z"
    />
    <path
      fill="#0091DA"
      d="M96.92 128.725c-50.082 28.359-118.827 86.24-90.166 201.257 28.665 115.014 140.256 115.355 223.47 83.84 83.214-31.508 274.061-209.08 393.313-282.44C742.782 58.016 763.544 10.82 656.119 4.856c-141.196-7.836-339.332-.635-559.2 123.868z"
    />
    <path
      fill="#000"
      fillOpacity=".15"
      d="M618.578 50.573c-39.307 20.931-89.14 54.022-91.517 82.692-2.37 28.66 64.624 34.513 105.044 31.806 40.424-2.707 137.287 6.549 164.2-19.148 26.918-25.697 3.133-67.313 28.646-97.651 25.514-30.338 15.52-48.31 4.733-47.585-10.78.721-137.083 10.459-211.106 49.886z"
    />
  </SVG>
);

export default Bubbles;

/* eslint no-case-declarations: 0 */

import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
export const SUBMIT_MATCH = 'SUBMIT_MATCH';
export const SUBMIT_ANSWER = 'SUBMIT_ANSWER';
export const REMOVE_MATCH = 'REMOVE_MATCH';
export const COMPLETE_MODULE = 'COMPLETE_MODULE';
export interface AnswerProp {
  moduleId: string;
  id: string;
  matches: Array<string>;
  answer?: string | null;
}

export interface MatchAnswerProp {
  moduleId: string;
  id: string;
  match: string;
}

export interface LongAnswerProp {
  moduleId: string;
  id: string;
  answer: string;
}

export interface AnswersProps {
  answers: Array<AnswerProp | undefined>;
  completeModules: [];
}

export interface MatchAnswerAction {
  type: typeof SUBMIT_MATCH;
  answer: MatchAnswerProp;
}

export interface CompleteModuleAction {
  type: typeof COMPLETE_MODULE;
  module: string;
}

export interface RemoveMatchAnswerAction {
  type: typeof REMOVE_MATCH;
  answer: MatchAnswerProp;
}

export interface LongAnswerAction {
  type: typeof SUBMIT_ANSWER;
  answer: LongAnswerProp;
}

export type ModulesActionTypes =
  | MatchAnswerAction
  | LongAnswerAction
  | RemoveMatchAnswerAction
  | CompleteModuleAction;

export function submitMatch(match: MatchAnswerProp): MatchAnswerAction {
  return {
    type: SUBMIT_MATCH,
    answer: match
  };
}

export function removeMatch(match: MatchAnswerProp): RemoveMatchAnswerAction {
  return {
    type: REMOVE_MATCH,
    answer: match
  };
}

export function completeModule(id: string): CompleteModuleAction {
  return {
    type: COMPLETE_MODULE,
    module: id
  };
}

export function submitAnswer(answer: LongAnswerProp): LongAnswerAction {
  return {
    type: SUBMIT_ANSWER,
    answer
  };
}

const modulesPersistConfig = {
  key: 'answers',
  storage
};

const modulesInitialState: AnswersProps = {
  answers: [],
  // we want to allow access to the module from module listing until an email has been sent. so, store the moduleIds that pass this requirement.
  completeModules: []
};

export function modules(
  state = modulesInitialState, // eslint-disable-line default-param-last
  action: ModulesActionTypes
): AnswersProps {
  const newAnswers = [...state.answers];
  let newCompleteModules: Array<string> = [...state.completeModules];

  switch (action.type) {
    case SUBMIT_ANSWER:
      const submittedAnswer = action.answer.answer;
      const answerModuleId = action.answer.moduleId;
      const answerSubmoduleId = action.answer.id;
      const existingAnswer: AnswerProp | undefined = newAnswers.find(
        (a: AnswerProp) =>
          a.moduleId === answerModuleId && a.id === answerSubmoduleId
      );

      if (existingAnswer) {
        existingAnswer.answer = submittedAnswer;
      } else {
        newAnswers.push({
          moduleId: answerModuleId,
          id: answerSubmoduleId,
          matches: [],
          answer: submittedAnswer
        });
      }

      return { ...state, answers: newAnswers };
    case REMOVE_MATCH:
      const matchToRemove: AnswerProp | undefined = newAnswers.find(
        (a: AnswerProp) =>
          a.moduleId === action.answer.moduleId && a.id === action.answer.id
      );

      if (matchToRemove) {
        matchToRemove.matches = matchToRemove.matches.filter(
          item => item !== action.answer.match
        );
      } else {
        return { ...state, answers: state.answers };
      }

      return { ...state, answers: newAnswers };
    case SUBMIT_MATCH:
      const existingMatch: AnswerProp | undefined = newAnswers.find(
        (a: AnswerProp) =>
          a.moduleId === action.answer.moduleId && a.id === action.answer.id
      );

      if (existingMatch) {
        existingMatch.matches.push(action.answer.match);
        existingMatch.matches = [...new Set(existingMatch.matches)];
      } else {
        newAnswers.push({
          moduleId: action.answer.moduleId,
          id: action.answer.id,
          matches: [action.answer.match],
          answer: null
        });
      }

      return { ...state, answers: newAnswers };
    case COMPLETE_MODULE:
      newCompleteModules.push(action.module);
      newCompleteModules = [...new Set(newCompleteModules)];
      console.log(newCompleteModules);

      // @ts-ignore
      return { ...state, completeModules: newCompleteModules };
    default:
      return state;
  }
}

const modulesReducer = persistReducer(modulesPersistConfig, modules);

export default modulesReducer;

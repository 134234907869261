import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export const SET_USER = 'SET_USER';
export const START_AGAIN = 'START_AGAIN';

const authPersistConfig = {
  key: 'auth',
  storage
};

export interface AuthProps {
  email: string | null;
  name: string | null;
}

export interface User {
  email: string;
  name: string;
}

export interface SetUserAction {
  type: typeof SET_USER;
  value: User;
}

export interface StartAgainAction {
  type: typeof START_AGAIN;
}

export type AuthActionTypes = SetUserAction | StartAgainAction;

export function setUser(newUser: User): SetUserAction {
  return {
    type: SET_USER,
    value: newUser
  };
}

export function startAgain(): StartAgainAction {
  return {
    type: START_AGAIN
  };
}

export const authInitialState: AuthProps = {
  email: '',
  name: ''
};

function auth(
  state = authInitialState, // eslint-disable-line default-param-last
  action: AuthActionTypes
): AuthProps {
  switch (action.type) {
    case SET_USER:
      return { email: action.value.email, name: action.value.name };
    case START_AGAIN:
      return authInitialState;
    default:
      return state;
  }
}

const authReducer = persistReducer(authPersistConfig, auth);

export default authReducer;

// import { navigate } from 'gatsby';
import React, { FC /* , useCallback*/ } from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import Bubbles from 'src/components/svg/Bubbles';
// import { startAgain } from '../state/actions';
import Query from 'src/utils/Queries';
import { keyframes } from 'styled-components';
import { styled, theme } from 'twin.macro';
import Logo from './svg/Logo';

const pulse = keyframes`
  0% {
    width:5%;
  }
  20% {
    width: 10%;
  }
  30% {
    width:40%;
  }
  60% {
    width:80%
  }
  90% {
    width: 90%
  }
  100% {
    width:98%
  }
`;

export const LogoWrap = styled.div`
  position: absolute;
  top: 50vh;
  left: 50%;
  width: 224px;
  height: auto;
  transform: translate(-50%, -50px);
`;
export const StyleLogo = styled.svg`
  width: 224px;
  height: auto;
`;
export const StyleBubbles = styled.svg`
  position: absolute;
  top: calc(100% - 270px);
  left: 50%;
  width: 750px;
  height: auto;
  transform: translate(-50%, 0);
  @media screen and (orientation: landscape) and (max-height: 420px) {
    bottom: auto;
    top: calc(100% - 100px);
  }
  ${Query.tablet} {
    bottom: -200px;
    left: 50%;
    width: 1050px;
  }
`;

export const TopBar = styled.div`
  height: 8px;
  background: ${theme`colors.grey-dark`};
  span {
    background: ${theme`colors.orange`};
    border-radius: 0 5px 5px 0;
    display: block;
    width: 0;
    height: 100%;
  }
`;

export const TopBarAnimated = styled(TopBar)`
  position: absolute;
  top: 0;
  height: 6px;
  right: 0;
  left: 0;
  span {
    width: 98%;
    animation: ${pulse} 3s;
  }
`;

export const Wrap = styled.div`
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100vh;
`;

export const Splash: FC = () => {
  return (
    <Wrap>
      <TopBarAnimated>
        <span />
      </TopBarAnimated>
      <StyleBubbles as={Bubbles} />
      <LogoWrap>
        <ScrollAnimation offset={0} animateIn="fadeInUp" delay={1000}>
          <StyleLogo as={Logo} />
        </ScrollAnimation>
      </LogoWrap>
    </Wrap>
  );
};

export default Splash;

import { applyMiddleware, combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { createLogger } from 'redux-logger';
import { persistStore } from 'redux-persist';
import thunkMiddleware from 'redux-thunk';
import answersReducer from './reducers/answers';
import authReducer from './reducers/auth';
const logger = createLogger({
  collapsed: true
});

export const rootReducer = combineReducers({
  auth: authReducer,
  answers: answersReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export const makeStore = () => {
  if (process) {
    if (process.env.NODE_ENV === 'development') {
      return createStore(
        rootReducer,
        composeWithDevTools(applyMiddleware(thunkMiddleware, logger))
      );
    }
  }

  return createStore(rootReducer);
};

const generateStore = () => {
  const store = makeStore();
  const persistor = persistStore(store);

  return { store, persistor };
};

export default generateStore;

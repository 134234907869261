const colors = {
  // Primary colours
  'blue': '#0091da',
  // Secondary colours
  'orange': '#f8971d',
  'navy': '#003a71',
  // Greys
  'grey': '#8a8a8a',
  'grey-light': '#c8c8c8',
  'grey-dark': '#333333',
  'grey-x-dark': '#181818',
  // Others
  'forest': '#10502f',
  'infrared': '#ff414a',
  'yellow': '#ffc500',
  'vibrant-aa': '#62a525',
  'blue-aa': '#0071b5',
  'infrared-aa': '#d62035',
  'error': '#e15d5d',
  'grey-icon': '#67696C',
  'white': '#ffffff',
  'black': '#000000',
  'transparent': 'transparent'
};

module.exports = {
  corePlugins: {
    float: false,
    inset: true
  },
  theme: {
    fontFamily: {
      display: 'Post Grotesk, Arial, sans-serif',
      heading: 'Gravur, Arial, sans-serif',
      body: 'Post Grotesk, Arial, sans-serif'
    },
    boxShadow: {
      'blue-left': `0 0 16px 0 ${colors.blue}, inset 2px 0 7px 0 ${colors.blue}`,
      'blue-right': `0 0 16px 0 ${colors.blue}, inset -2px 0 7px 0 ${colors.blue}`,
      'orange-left': `0 0 16px 0 ${colors.orange}, inset 2px 0 7px 0 ${colors.orange}`,
      'orange-right': `0 0 16px 0 ${colors.orange}, inset -2px 0 7px 0 ${colors.orange}`,
      'blue': `0 0 24px ${colors.blue}, 0 0 12px ${colors.blue}, 0 0 6px ${colors.blue}`,
      'blue-light': `0 0 24px ${colors.blue}`,
      'orange': `inset 0 0 10px 0 ${colors.orange}, 0 0 10px 0 ${colors.orange},0 0  8px 0 ${colors.orange}`
    },
    borderRadius: {
      default: '4px',
      large: '8px',
      full: '1000px'
    },
    screens: {
      sm: '0px',
      md: '760px'
    },
    container: {
      center: true
    },
    colors,
    extend: {
      gridTemplateColumns: {
        // Simple 6-column grid
        6: 'repeat(6, minmax(0, 1fr))'
      }
    }
  }
};

import Query from 'src/utils/Queries';
import tailwindConfig from 'src/utils/TailwindConfig';
import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import { theme } from 'twin.macro';
const bodyFont = tailwindConfig.theme.fontFamily.body;
const headingFont = tailwindConfig.theme.fontFamily.heading;
const FONTS_DIR = '/fonts';

export const GlobalStyle = createGlobalStyle`
  ${normalize};
  @font-face {
    font-family: "Gravur";
    font-weight: normal;
    src: url("${FONTS_DIR}/GravurLLWeb-CondRegular.woff2") format("woff2");
  }

  @font-face {
    font-family: "Gravur";
    font-weight: normal;
    src: url("${FONTS_DIR}/GravurLLWeb-CondRegular.woff") format("woff");
  }

  @font-face {
    font-family: "Gravur";
    font-weight: 100;
    src: url("${FONTS_DIR}/GravurLLWeb-CondLight.woff2") format("woff2");
  }

  @font-face {
    font-family: "Gravur";
    font-weight: 100;
    src: url("${FONTS_DIR}/GravurLLWeb-CondLight.woff") format("woff");
  }

  @font-face {
    font-family: "Post Grotesk";
    src: url("${FONTS_DIR}/PostGrotesk-Book.woff2") format("woff2");
  }

  @font-face {
    font-family: "Post Grotesk";
    font-style: italic;
    src: url("${FONTS_DIR}/PostGrotesk-BookItalic.woff2") format("woff2");
  }

  @font-face {
    font-family: "Post Grotesk";
    font-style: italic;
    src: url("${FONTS_DIR}/PostGrotesk-BookItalic.woff") format("woff");
  }

  @font-face {
    font-family: "Post Grotesk";
    font-weight: bold;
    src: url("${FONTS_DIR}/PostGrotesk-Bold.woff2") format("woff2");
  }

  @font-face {
    font-family: "Post Grotesk";
    font-weight: bold;
    src: url("${FONTS_DIR}/PostGrotesk-Bold.woff") format("woff");
  }

  @font-face {
    font-family: "Post Grotesk";
    font-weight: bold;
    font-style: italic;
    src: url("${FONTS_DIR}/PostGrotesk-BoldItalic.woff2") format("woff2");
  }

  @font-face {
    font-family: "Post Grotesk";
    font-weight: bold;
    font-style: italic;
    src: url("${FONTS_DIR}/PostGrotesk-BoldItalic.woff") format("woff");
  }

  @font-face {
    font-family: "Post Grotesk";
    font-weight: 300;
    src: url("${FONTS_DIR}/PostGrotesk-Light.woff2") format("woff2");
  }

  @font-face {
    font-family: "Post Grotesk";
    font-weight: 300;
    src: url("${FONTS_DIR}/PostGrotesk-Light.woff") format("woff");
  }

  @font-face {
    font-family: "Post Grotesk";
    font-weight: 300;
    font-style: italic;
    src: url("${FONTS_DIR}/PostGrotesk-LightItalic.woff2") format("woff2");
  }

  @font-face {
    font-family: "Post Grotesk";
    font-weight: 300;
    font-style: italic;
    src: url("${FONTS_DIR}/PostGrotesk-LightItalic.woff") format("woff");
  }
  /* apply a natural box layout model to all elements, but allowing components to change */
  html {
    height: 100%;
    text-size-adjust: 100%;
    box-sizing: border-box;
    /* overflow-x: hidden; */
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    height:100%;
    box-sizing: border-box;
    font-family: ${bodyFont};
    font-size: 100%;
    line-height: 1.26;
    background-color: ${theme`colors.black`};
    color: ${theme`colors.white`};
  }
  .tl-edges{
    overflow-x: initial !important;
  }
  #gatsby-focus-wrapper{
    height: 100%;
    min-height: 100%;
    > div{
      height: 100%;
      min-height: 100%;
    }
  }

  .o-hidden{
    overflow:hidden;
  }

  body > div{
    height:100%;
  }

  h1,h2,h3,h4,h5 {
    font-family: ${headingFont};
    font-weight: normal;
    letter-spacing: -0.2px;
    margin:0 0 1rem;
  }

  h1 {
    font-size: 30px;
    ${Query.tablet} {
      font-size: 40px;
    }
  }

  h2 {
    font-size: 22px;
    ${Query.tablet} {
      font-size: 26px;
    }
  }

  h3 {
    font-size: 19px;
  }

  h4 {
    font-size: 17px;
    text-transform: uppercase;
  }

  p {
    font-weight: 300;
    margin:0 0 1rem 0;
    line-height: 24px;
  }
  p > svg{
    display:block;
    overflow:visible;
    margin:0 auto;
  }
  /* h1 + p {
    margin-top:-10px;
  } */
  img {
    max-width: 100%;
  }
  button {
    appearance: none;
    border: 0;
    -webkit-appearance: none;
    background: transparent;
  }
  .svg-sprite {
    position: absolute;
    overflow: hidden;
  }
  .svg-icon--flip {
    transform: rotate(180deg);
  }
`;

export default GlobalStyle;

import React, { FC, ReactNode } from 'react';

const SVG: FC<{
  width: number;
  height: number;
  viewBox: string;
  children: ReactNode;
  className?: string;
  title?: string;
}> = ({ width, height, children, viewBox, className = '', title = '' }) => (
  <svg
    className={`svg-icon ${className}`}
    width={width}
    height={height}
    viewBox={viewBox}
    xmlns="http://www.w3.org/2000/svg"
  >
    {title !== '' && <title>{title}</title>}
    {children}
  </svg>
);

export default SVG;
